import React, { useState } from "react";
import { toast } from "react-toastify";
// import { NavLink } from "react-router-dom";
import {LineWave} from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/altrafxlogo_black.png";
// import bannerimg from "../../assets/userbanner.png";
import { useNavigate } from "react-router-dom";
// import logo from "../assets/logo.png";
// import { IoIosEye } from "react-icons/io";
// import { IoIosEyeOff } from "react-icons/io";
import axios from "axios";
// import { PiArrowSquareRight } from "react-icons/pi";
// import styles from "../../components/tradeall.module.css"
import bannerimg from "../../assets/otpilllus.webp"
import styles from "../../components/tradeall.module.css"

const Verifyotp = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [Loading , setLoading]=useState(false)




const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };



  const verifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true)
    const email = localStorage.getItem("userEmail");
    // console.log("email" ,email)

    if (!email) {
        // console.error("Email not found. Please go back and enter your email.");
        toast.error("Email not found. Please enter correct again.");
        return;
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_URL}/user/verifyOTP`,
            { otp , email }  
        );
        setLoading(false)
        // console.log("response" ,response)
        // console.log("otp" ,email)
        // console.log("otp" ,email)
        if (response.status === 200) {
            
            toast.success("OTP verified successfully.");
            navigate("/user/changepassword"); 
        } else if(response.status === 500 || response.status === 401) {
           
            toast.error("Incorrect OTP. Please try again.");
        }
        else{
            console.log("error");
            toast.error("Error verifying OTP");
        }
    } catch (error) {
        setLoading(false)
        console.error("Error verifying OTP:", error);
        toast.error("Error verifying OTP. Please check again .");
    }
};


      // const handleResendCode = async () => {
      //   try {
      //     // Make API request to resend OTP
      //     console.log("Resending OTP...");
      //   } catch (error) {
      //     console.error("Error resending OTP:", error);
      //     // Handle error
      //   }
      // };
      
  return (
    <div>
    

    <section className={`${styles.usersignin_bg}  `}>
       <div className="py-[3.5rem] sm:py-[3.5rem] md:py-[4.5rem] lg:py-[5.5rem] xl:py-[7.5rem]  px-[1.5rem] sm:px-[1.5rem] md:px-[4rem] lg:px-[5rem] xl:px-[7rem] h-full flex w-[100%] flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">

       <div className="w-[100%] sm:w-[100%] md:w-[100%]  lg:w-[50%] xl:w-[50%] flex justify-center items-center ">
          <img
            src={bannerimg}
            alt="bannerimg"
            className="h-[35vh] sm:h-[35vh] md:h-[35vh] lg:h-[55vh] xl:sm:h-[55vh]"
          ></img>
        </div>
        <div className="mt-10 sm:mt-10 md:mt-10 lg:mt-0 xl:mt-0 w-[100%] z-[999] sm:w-[100%] md:w-[100%]  lg:w-[50%] xl:w-[50%] flex flex-col items-center justify-center  py-8 lg:py-0 ">
      
        <div className="rounded-[34px]  flex items-center justify-center sm:px-[2px] md:px-[6.5rem] lg:px-[4.5rem] xl:px-[4.5rem] px-0 py-0 sm:py-0 md:py-[3.5rem] lg:py-[4.5rem] xl:py-[8.5rem]  bg-white" style={{ boxShadow: '0px 2px 14.1px 0px rgba(223, 223, 223, 0.50)' }}>
        <div className="w-full bg-white     md:mt-0 sm:max-w-md xl:p-0  ">
            <div className="space-y-4 md:space-y-4  bg-white">
              <h1 className="text-[24px] sm:text-[24px] md:text-[32px] lg:text-[32px] xl:text-[32px] font-bold  text-[#151841] text-center">Verify Code</h1>

              <p className="text-[#170E32] text-center font-semibold text-[14px] sm:text-[14px] md:sm:text-[14px] lg:sm:text-[16px] xl:sm:text-[16px]"> Please enter the code that we have sent to your registered mail</p>
              <form  className="my-10" onSubmit={verifyOTP}>
                <div className="flex flex-col space-y-5 justify-center items-center">
                <input
            type="text"
            className="text-center p-3 border-none  border-2 w-[300px] text-[30px] border-black outline-2 outline-black text-black"
            style={{ letterSpacing: 10, border: "none", outline: "none" }}
            placeholder="- - - - - -"
            name=""
            id=""
            maxLength="6"
            value={otp}
            onChange={handleOtpChange}
          />
                
                   
                   
                  <button
                    type="Verify"
                    className="login-btn w-full py-3  bg-[#151841] font-bold text-[#fff] rounded-lg  inline-flex space-x-2 items-center justify-center mt-10"
                  >
                    <span onSubmit={verifyOTP} >Send Verification Code</span>
                    {/* <PiArrowSquareRight className="text-xl" /> */}
                  </button>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      





          {
             Loading ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
             <LineWave 
               height="60"
               width="60"
               color="#111827"
               ariaLabel="bars-loading"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
               className=" w-10 h-10 flex items-center justify-center  mt-10 "
             />
           </div> :
            <div>
            
           </div>
          }
      
        </div>

       </div>

      </section>


    </div>
  )
}

export default Verifyotp

import React, { useState } from "react";
import { toast } from "react-toastify";
// import { NavLink } from "react-router-dom";
import {LineWave} from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/altrafxlogo_black.png";
// import bannerimg from "../../assets/userbanner.png";
import { useNavigate } from "react-router-dom";
// import logo from "../assets/logo.png";
// import { IoIosEye } from "react-icons/io";
// import { IoIosEyeOff } from "react-icons/io";
import axios from "axios";
// import { PiArrowSquareRight } from "react-icons/pi";
// import styles from "../../components/tradeall.module.css"
import bannerimg2 from "../../assets/forgotpass.webp"
import styles from "../../components/tradeall.module.css"

const Forgetpassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [isCodesend, setIsCodesend] = useState(false);
  const [Loading, setLoading] = useState(false);
  

  
  const validateEmail = (email) => {
    const emailRegex = /.+@.+/;
    return emailRegex.test(email);
  };


  const handleSubmit = async (e) => {
    
    e.preventDefault();
    if(!email){
      toast.error("Email is required", {
        position: "top-right",
      });
      return;
    }
    setLoading(true)
    setIsCodesend(true);
    if (!validateEmail(email)) {
      toast.error("Invalid email address , @ is mendatory", {
        position: "top-right"});
        setIsCodesend(false);
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/forgotPasswordotp`,
        { email },
        {headers: {"Content-Type": "application/json"},
        }
      );
      localStorage.setItem("userEmail", email);
      setLoading(false)
      setIsCodesend(true);
      console.log("response" , response)
      const data = await response.data;
      console.log("data00" , data)
    //   localStorage.setItem("username" , response.data.user.name )
    //   const userregisterId = response.data.user._id          ;
    //   localStorage.setItem("userregisterId" , userregisterId )
    //   localStorage.setItem("usertoken", data.usertoken);
      setEmail("");
      // setPassword("");

      navigate("/user/verifyotp");
    } catch (error) {
      setLoading(false)
      setIsCodesend(false);
      console.error("Error during login:", error);
      toast.error("Error during login! Please check credientials ", {
        position: "top-right",
      });
    }
  };

  return (
    <>
      <section className={`${styles.usersignin_bg}  `}>
     <div className="py-[3.5rem] sm:py-[3.5rem] md:py-[4.5rem] lg:py-[5.5rem] xl:py-[7.5rem]  px-[1.5rem] sm:px-[1.5rem] md:px-[4rem] lg:px-[5rem] xl:px-[7rem] h-full flex w-[100%] flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">

     <div className="w-[100%] sm:w-[100%] md:w-[100%]  lg:w-[50%] xl:w-[50%] flex justify-center items-center ">
          <img
            src={bannerimg2}
            alt="bannerimg"
            className="h-[35vh] sm:h-[35vh] md:h-[35vh] lg:h-[55vh] xl:sm:h-[55vh]"
          ></img>
        </div>
        <div className=" w-[100%] z-[999] sm:w-[100%] md:w-[100%]  lg:w-[50%] xl:w-[50%] flex flex-col items-center justify-center  py-8 lg:py-0 ">
         
         <div className="rounded-[34px]  flex items-center justify-center sm:px-[2px] md:px-[6.5rem] lg:px-[4.5rem] xl:px-[4.5rem] px-0 py-0 sm:py-0 md:py-[3.5rem] lg:py-[4.5rem] xl:py-[8.5rem]  bg-white" style={{ boxShadow: '0px 2px 14.1px 0px rgba(223, 223, 223, 0.50)' }}>
         <div className="w-full bg-white     md:mt-0 sm:max-w-md xl:p-0  ">
            <div className="space-y-4 md:space-y-4  bg-white">
              <h1 className="text-[24px] sm:text-[24px] md:text-[32px] lg:text-[32px] xl:text-[32px] font-bold  text-[#151841] text-center">Verify Email</h1>

              <p className="text-[#170E32] text-center font-semibold text-[14px] sm:text-[14px] md:sm:text-[14px] lg:sm:text-[16px] xl:sm:text-[16px] ">Enter your Email Address from which you’ve created your account</p>
              <form onSubmit={handleSubmit} className="my-10">
                <div className="flex flex-col space-y-5">
                  <label htmlFor="email">
                    <p className="font-bold text[16px] text-[#170E32] pb-2 mt-5">
                       Email address
                    </p>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                      className="w-full py-3  input-field border border-[#8692A6] rounded-lg px-3  placeholder-[#545454] font-semibold "
                      placeholder="Enter email"
                    />
                  </label>
                
                   
                   
                  <button
                    type="submit"
                    className="login-btn w-full py-3 bg-[#151841] font-bold text-[#fff]  rounded-lg  hover:shadow inline-flex space-x-2 items-center justify-center mt-10"
                  >
                    <span>{isCodesend ? "Sending OTP..." : "Send Verification   Code"}</span>
                    {/* <PiArrowSquareRight className="text-xl" /> */}
                  </button>
                 
                </div>
              </form>
            </div>
          </div>
         </div>
      





          {
             Loading ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
             <LineWave 
               height="60"
               width="60"
               color="#111827"
               ariaLabel="bars-loading"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
               className=" w-10 h-10 flex items-center justify-center  mt-10 "
             />
           </div> :
            <div>
            
           </div>
          }
      
        </div>

     </div>

      </section>
    </>
  );
};

export default Forgetpassword;

import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LineWave } from "react-loader-spinner";
const Adminbuysell = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSell, setLoadingSell] = useState(false);
  const handleClick = async (value) => {
    try {
      if (value === "buy") {
        setLoading(true);
      } else  {
        setLoadingSell(true);
      }
      const userid = localStorage.getItem("adminregisterId");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/postbuysell`,
        {
          userid: userid,
          value: value,
        }
      );

      console.log(response);
      if (value === "buy") {
        setLoading(false);
      } else {
        setLoadingSell(false);
      }
      toast.success(`${value} command send successfully!`, {
        position: "top-right",
      });
    } catch (error) {
      console.error("Error posting buy/sell data:", error);
      toast.error(`Error in sending ${value} command!`, {
        position: "top-right",
      });
      if (value === "buy") {
        setLoading(false);
      } else {
        setLoadingSell(false);
      }
    }
  };
  return (
    <>
      <div className="flex justify-center items-center mt-2">
        <button
          className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${
            loading ? "opacity-50 cursor-not-allowed disabled" : ""
          }`}
          onClick={() => handleClick("buy")}
        >
          Buy
        </button>
        <button
          className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 m-2 ${
            loadingSell ? "opacity-50 cursor-not-allowed disabled" : ""
          }`}
          onClick={() => handleClick("sell")}
        >
         Sell
        </button>
      </div>
    </>
  );
};

export default Adminbuysell;

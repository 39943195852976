import React, { useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/altrafxlogo_black.png";
import bannerimg from "../../assets/userbanner.png";
import { useNavigate } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import {LineWave} from "react-loader-spinner";
const Signupuser = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");
  const [country, setcountry] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [userregisterid , setuserregisterid]  = useState("");
  const [Loading, setLoading] = useState(false);

  const [verifymailotp, setVerifyMailOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  
  const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


  const validateEmail = (email) => {
    const emailRegex = /.+@.+/;
    return emailRegex.test(email);
  };

  
  const validatePhoneNumber = (phonenumber) => {
    const phoneRegex = /^\d{10,}$/;
    return phoneRegex.test(phonenumber);
  };



  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[0-9!@#$%^&*])(?=.{4,})/;
    return passwordRegex.test(password);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateEmail(email)) {
      toast.error("Invalid email address, @ is mandatory", {
        position: "top-right",
      });
      return;
    }
  
    if (!validatePhoneNumber(phonenumber)) {
      toast.error("Phone number should be at least 10 digits", {
        position: "top-right",
      });
      return;
    }
    if (!validatePassword(password)) {
      toast.error("Password should not be less than 4 characters and include at least one number or special character", {
        position: "top-right",
      });
      return;
    }
  
    if(!email || !password || !name || !phonenumber || !city || !country  || !verifymailotp){
      toast.error("All fields are required", {
        position: "top-right",
      });
      return;
    }
    try {
      console.log("TRY");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/usersignup`,
        {
          name,
          email,
          phonenumber,
          city , country,
          password, 
          otp: verifymailotp ,
          approvalStatus : 'pending'
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: (status) => {
            return status >= 200 && status < 500;  // Accept all status codes between 200 and 499
          },
        }
      );
  
      // console.log(response.data);
  
      if (response.status === 200) {
          // const userregisterId = response.data.savedUser._id          ;
          // localStorage.setItem("userregisterId" , userregisterId )
          // console.log("userregisterId" , userregisterId)
        toast.success("Registration successful!", { position: "top-right" });
        toast.success("You will be notified on email, when admin approves you", { position: "top-right" });
        // console.log("User registration successful ", response);
        setEmail("");
        setName("");
        setPhonenumber("");
        setCity("");
        setcountry("");
        setPassword("");
        setVerifyMailOtp("");
        navigate("/");
      } else if (response.status === 409) {
        toast.error("Email already exists", { position: "top-right" });
      } 
      else if (response.status === 404) {
        toast.error("Resource not found", { position: "top-right" });
      } 
     else if (response.status === 400) {
       
        toast.error('OTP did not match', { position: 'top-right' });
      }
      else {
        toast.error("Unexpected response status: " + response.status, { position: "top-right" });
      }
    } 
    catch (error) {
      console.error("Error during registration:", error);
      console.log("HELLO");
      toast.error("Error during registration, please fill all fields", {
        position: "top-right",
      });
    }
  };
  

  const sendOtp = async () => {
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_URL}/user/sendOtp`, { email });
      console.log("response" ,response.data)
      if (response.data.message === 'OTP sent successfully') {
        setOtpSent(true);
        setLoading(false)
        toast.success('OTP sent to your email', { position: 'top-right' });

      }
    } catch (error) {
      setLoading(false)
      toast.error('Failed to send OTP , please enter correct email id', { position: 'top-right' });
    }
  };

  return (
    <>
      <section className="bg-gray-50  flex w-[100%] flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row">
        <div className="w-[100%] sm:w-[100%] md:w-[50%]  lg:w-[50%] xl:w-[50%] flex justify-center items-center h-[50vh] sm:h-[50vh] md:h-[100vh] lg:h-[100vh] xl:sm:h-[100vh]">
          <img
            src={bannerimg}
            alt="bannerimg"
            className="h-[80vh] sm:h-[80vh] md:h-[80vh] lg:h-[80vh] xl:sm:h-[80vh]"
          ></img>
        </div>
        <div className="pt-2 w-[100%] sm:w-[100%] md:w-[50%]  lg:w-[50%] xl:w-[50%] flex flex-col items-center justify-center px-6 py-8 lg:py-0 bg-[#f1f1f1]">
          <a
            href="/"
            className="flex items-center mb-4 text-2xl font-semibold text-gray-900 "
          >
            <img className="h-12 w-26" src={logo} alt="logo" />
            
          </a>

        
          <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 mb-5">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Create your user account
              </h1>
              <form
                onSubmit={handleSubmit}
                className="space-y-4 md:space-y-4"
                action="#"
              >
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                    placeholder="Your Name"
                    required=""
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    value={email}
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                    placeholder="name@gmail.com"
                    required=""
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="phonenumber"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Phone Number
                  </label>
                  <input
                    type="phonenumber"
                    value={phonenumber}
                    name="phonenumber"
                    id="phonenumber"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5      "
                    placeholder="12345678"
                    required=""
                    onChange={(e) => setPhonenumber(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="text"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    City
                  </label>
                  <input
                    type="text"
                    value={city}
                    name="city"
                    id="city"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="city"
                    required=""
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="brokerid"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    value={country}
                    name="country"
                    id="country"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="country"
                    required=""
                    onChange={(e) => setcountry(e.target.value)}
                  />
                </div>
               

                <div>
                  <label htmlFor="password" className="relative">
                    <p className="font-medium text-slate-700 pb-2">Password</p>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                      className="w-full py-3 input-field border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow pr-10"
                      placeholder="A Secure Password"
                    />
                    {showPassword ? (
                      <IoIosEye
                        className="absolute top-[85%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      // <img src={eye_img_open}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                      <IoIosEyeOff
                        className="absolute top-[85%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      // <img src={eye_img}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                    )}
                  </label>
                </div>
  <div>
          <div className="flex justify-between">  <label className="block mb-2 text-sm font-medium text-gray-900">Verify Mail</label>
            <label className=" cursor-pointer underline block mb-2 text-sm font-medium text-[#234ec9]" onClick={sendOtp}>send otp to mail</label></div>
            <input
              type="text"
              value={verifymailotp}
              onChange={(e) => setVerifyMailOtp(e.target.value)}
              className="w-full p-2 border rounded"
              required
              placeholder="Enter Otp"
            />
          </div>


                

                 <button
          data-modal-target="progress-modal" data-modal-toggle="progress-modal"
            type="submit"
            className="block submit-btn bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  bg-gray-900  p-2 mt-5 text-white w-full hover:bg-gray-700"
          >
            Submit for Approval
          </button>
                <p className="text-sm font-light text-gray-500  mt-0">
                  Already have an account?{" "}
                  <a
                    href="/"
                    className="font-medium text-primary-600 hover:underline "
                  >
                    <NavLink to="/"> Login here</NavLink>
                  </a>
                </p>
              </form>
               
            </div>
          </div>



          {
             Loading ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
             <LineWave 
               height="60"
               width="60"
               color="#111827"
               ariaLabel="bars-loading"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
               className=" w-10 h-10 flex items-center justify-center  mt-10 "
             />
           </div> :
            <div>
            
           </div>
          }
      
        </div>

        

{/* <!-- Modal toggle --> */}
{/* <button
                onClick={handleOpenModal}
                className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center   "
            >
                Toggle modal
            </button> */}

    
            {isModalOpen && (
                <div
                    id="progress-modal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full overflow-y-auto overflow-x-hidden"
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow ">
                            <button
                                type="button"
                                onClick={handleCloseModal}
                                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5">
                                <svg
                                    className="w-10 h-10 text-gray-400 "
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 16 20"
                                >
                                    <path d="M8 5.625c4.418 0 8-1.063 8-2.375S12.418.875 8 .875 0 1.938 0 3.25s3.582 2.375 8 2.375Zm0 13.5c4.963 0 8-1.538 8-2.375v-4.019c-.052.029-.112.054-.165.082a8.08 8.08 0 0 1-.745.353c-.193.081-.394.158-.6.231l-.189.067c-2.04.628-4.165.936-6.3.911a20.601 20.601 0 0 1-6.3-.911l-.189-.067a10.719 10.719 0 0 1-.852-.34 8.08 8.08 0 0 1-.493-.244c-.053-.028-.113-.053-.165-.082v4.019C0 17.587 3.037 19.125 8 19.125Zm7.09-12.709c-.193.081-.394.158-.6.231l-.189.067a20.6 20.6 0 0 1-6.3.911 20.6 20.6 0 0 1-6.3-.911l-.189-.067a10.719 10.719 0 0 1-.852-.34 8.08 8.08 0 0 1-.493-.244C.112 6.035.052 6.01 0 5.981V10c0 .837 3.037 2.375 8 2.375s8-1.538 8-2.375V5.981c-.052.029-.112.054-.165.082a8.08 8.08 0 0 1-.745.353Z"/>
                                </svg>
                                <h3 className="mb-1 text-xl font-bold text-gray-900 ">Approaching Full Capacity</h3>
                                <p className="text-gray-500  mb-6">Choosing the right server storage solution is essential for maintaining data integrity.</p>
                                <div className="flex justify-between mb-1 text-gray-500 ">
                                    <span className="text-base font-normal">My storage</span>
                                    <span className="text-sm font-semibold text-gray-900 ">376.3 of 500 GB used</span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2.5 ">
                                    <div className="bg-orange-500 h-2.5 rounded-full" style={{ width: "85%" }}></div>
                                </div>
                                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                                    <button
                                        type="button"
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center   "
                                        onClick={handleCloseModal}
                                    >
                                        Upgrade to PRO
                                    </button>
                                    <button
                                        type="button"
                                        className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100     "
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

      </section>
    </>
  );
};

export default Signupuser;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/bgimage60.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.tradeall_usersignin_bg__C7fWE {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}














@media (min-width:320px) {
.tradeall_forgetpassword__3kjPy{
margin-top:2px!important;
}
}


@media(min-width:320px) and (max-width:400px){
    .tradeall_tableheader__vwTjI{
margin-top:60%;
    }
    .tradeall_data_missing__lcqog{
        margin-top:65%;
    }
    .tradeall_table__lFSUZ{
        margin-top:116%;
    }
    .tradeall_tableuser__zaKSn{
        margin-top:0%;
    }
   
    /* .btncss1{
        margin: 5% 0;
    } */
    .tradeall_hidebtn__M3GM0{
        margin-left:-35%
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/tradeall.module.css"],"names":[],"mappings":";;AAEA;IACI,yDAAiD;AACrD;;;;;;;;;;;;;;;AAeA;AACA;AACA,wBAAwB;AACxB;AACA;;;AAGA;IACI;AACJ,cAAc;IACV;IACA;QACI,cAAc;IAClB;IACA;QACI,eAAe;IACnB;IACA;QACI,aAAa;IACjB;;IAEA;;OAEG;IACH;QACI;IACJ;AACJ","sourcesContent":["\n\n.usersignin_bg {\n    background-image: url(\"../assets/bgimage60.webp\");\n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n@media (min-width:320px) {\n.forgetpassword{\nmargin-top:2px!important;\n}\n}\n\n\n@media(min-width:320px) and (max-width:400px){\n    .tableheader{\nmargin-top:60%;\n    }\n    .data_missing{\n        margin-top:65%;\n    }\n    .table{\n        margin-top:116%;\n    }\n    .tableuser{\n        margin-top:0%;\n    }\n   \n    /* .btncss1{\n        margin: 5% 0;\n    } */\n    .hidebtn{\n        margin-left:-35%\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usersignin_bg": `tradeall_usersignin_bg__C7fWE`,
	"forgetpassword": `tradeall_forgetpassword__3kjPy`,
	"tableheader": `tradeall_tableheader__vwTjI`,
	"data_missing": `tradeall_data_missing__lcqog`,
	"table": `tradeall_table__lFSUZ`,
	"tableuser": `tradeall_tableuser__zaKSn`,
	"hidebtn": `tradeall_hidebtn__M3GM0`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { NavLink } from "react-router-dom";
import {LineWave} from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/altrafxlogo_black.png";
import adminbanner from "../assets/adminbanner.png";
import { useNavigate } from "react-router-dom";
// import logo from "../assets/logo.png";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import axios from "axios";
import { PiArrowSquareRight } from "react-icons/pi";


const Signin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [Loading, setLoading] = useState(false);






  useEffect(() => {
    verifytokenexistss()
  }, []);

  
  const verifytokenexistss = async () => {
    try {
      const tokenfromls = localStorage.getItem("token");
      // console.log("tokenfromls" , tokenfromls)

      const id = localStorage.getItem("adminregisterId");
      const h = { authorization: tokenfromls };
      console.log(h);
      if (tokenfromls) {
        const res = await axios.get(`
          ${process.env.REACT_APP_URL}/protect/protectedroute`,
          { headers: h }
        );
        // console.log("my resp", res);
       
        if (res) {
          // console.log(res.data , "cybercure")
          if (res.data.m == id) {
            navigate("/admin/economicdata");
          }
        }
      } 
    } catch (e) {
      console.log(e)
      if(e.response&&e.response.status===401||e.response&&e.response.status===400){
        console.log("Token malfunction");
       
      }
    
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/admin/economicdata");
    }
  }, []);



  const validateEmail = (email) => {
    const emailRegex = /.+@.+/;
    return emailRegex.test(email);
  };

  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);
    if (!validateEmail(email)) {
      toast.error("Invalid email address , @ is mendatory", {
        position: "top-right",
      });
      setIsLoggingIn(false);
      return;
    }

    
    setLoading(true)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/admin/login`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoggingIn(true);
      const data = await response.data;
      setLoading(false)
      console.log("data" , data)
      const adminregisterId = response.data.user._id ;
      localStorage.setItem("adminregisterId" , adminregisterId )
      // localStorage.setItem("usertoken", data.usertoken);
      localStorage.setItem("token", data.token);
      setEmail("");
      setPassword("");

      navigate("/admin/economicdata");
    } catch (error) {
      setIsLoggingIn(false);
      console.error("Error during login:", error);
      toast.error("Error during login! Please check credientials again", {
        position: "top-right",
      });
      setLoading(false)
    }
  };

  return (
    <>
     <section className="bg-gray-50 dark:bg-gray-900 flex w-[100%] flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row">
     <div className="w-[100%] sm:w-[100%] md:w-[50%]  lg:w-[50%] xl:w-[50%] flex justify-center items-center h-[50vh] sm:h-[50vh] md:h-[100vh] lg:h-[100vh] xl:sm:h-[100vh]">
          <img src={adminbanner} alt="bannerimg" className="h-[50vh] sm:h-[50vh] md:h-[80vh] lg:h-[80vh] xl:sm:h-[80vh]"></img>
        </div>

        <div className="w-[100%] sm:w-[100%] md:w-[50%]  lg:w-[50%] xl:w-[50%] flex flex-col items-center justify-center px-6 py-8 lg:py-0 bg-[#f1f1f1]">
          <a
            href="/"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img className="h-12 w-26" src={logo} alt="logo" />
           
          </a>

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-4xl font-medium">Login</h1>

              <p className="text-slate-500">Hi Admin, Welcome back 👋</p>
              <form onSubmit={handleSubmit} className="my-10">
                <div className="flex flex-col ">
                  <label htmlFor="email">
                    <p className="font-medium text-slate-700 pb-2">
                      Your email
                    </p>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                      className="w-full py-3  input-field border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                      placeholder="name@gmail.com"
                    />
                  </label>
                  <label htmlFor="password" className="relative">
                    <p className="font-medium text-slate-700 pb-2">Password</p>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                      className="w-full py-3 input-field border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow pr-10"
                      placeholder="A Secure Password"
                    />
                    {showPassword ? (
                      <IoIosEye
                        className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      // <img src={eye_img_open}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                      <IoIosEyeOff
                        className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      // <img src={eye_img}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                    )}
                  </label>

                  <button
                    type="submit"
                    className="login-btn w-full py-3 font-medium text-white bg-gray-900 hover:bg-gray-700 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center mt-10"
                  >
                      <span>{isLoggingIn ? "Logging you in..." : "Login"}</span>
                    {/* <span>Login</span> */}
                    <PiArrowSquareRight className="text-xl" />
                  </button>

                 
                </div>
              </form>
            </div>
          </div>



          {
             Loading ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
             <LineWave 
               height="60"
               width="60"
               color="#111827"
               ariaLabel="bars-loading"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
               className=" w-10 h-10 flex items-center justify-center  mt-10 "
             />
           </div> : <div>
            
           </div>
          }
        </div>
        
      </section>
    </>
  );
};

export default Signin;

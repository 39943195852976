import React, { useEffect, useState } from "react";
import styles from "../../components/tradeall.module.css";
import axios from "axios";
import UserNavbar from "../../components/user/userNavbar";
import { toast } from "react-toastify";
import RingLoader from "react-spinners/RingLoader";
import Selects from 'react-select'
import { FiCheckCircle } from "react-icons/fi";
import {LineWave} from "react-loader-spinner";

import { Select } from "antd";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;



const Savedtrade = () => {
  const [savedtradedatavalues, setSavedtradedatavalues] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Loading1, setLoading1] = useState(false);
  const [refetchData, setRefetchData] = useState(false);


  const [sendUpdatedItemToBackend , set_SendUpdatedItemToBackend] = useState([])

  const [dateRange, setDateRange] = useState(null); 
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [tradelogchange , setTradelogchange] = useState([])
  const { Option } = Select;

  const savedtradedata = async () => {
    try{
      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId")
    const savedresponse = await axios.get(
      `${process.env.REACT_APP_URL}/user/getsavedtradingdata/${userid}`, {headers: h}
    );
    setLoading(false)
    if (savedresponse.status === 200) {
  
    const latestDocuments = savedresponse.data.latestDocuments;
  
    let allTrades = [];

    latestDocuments.forEach((doc) => {
      allTrades = allTrades.concat(doc.trades);
    });
    setSavedtradedatavalues(allTrades);
    // console.log("allTrades", allTrades);
    }
  
  if(savedresponse.status === 404){
    toast.error("No data found in history.", { position: "top-right" });
    setLoading(false)
    return;
  }
}
    catch(error){
      console.log("error" , error)
      setLoading(false)
    }
  };

  // const sortedsavedData = [...savedtradedatavalues].sort(
  //   (a, b) => new Date(b.date) - new Date(a.date)
  // );
  const sortedsavedData = [...savedtradedatavalues].sort((a, b) => {
    const dateComparison = new Date(b.date) - new Date(a.date);
    
    // If dates are the same, compare by time
    if (dateComparison === 0) {
      return new Date(`1970-01-01T${b.time}`) - new Date(`1970-01-01T${a.time}`);
    }
  
    return dateComparison;
  });
  

  useEffect(() => {
    savedtradedata();
  }, [refetchData]); 

  useEffect(() => {
    if (refetchData) {
      // Reset refetchData to false after data is refetched
      setRefetchData(false);
    }
  }, [refetchData]);

  const trade_log = [
    { value: 'Profit', label: 'Profit' },
    { value: 'Loss', label: 'Loss' },
    { value: 'N.A', label: 'N.A' }
  ];

  const prediction = [
    { value: 'Correct', label: 'Correct' },
    { value: 'Wrong', label: 'Wrong' },
    { value: 'N.A', label: 'N.A' }
  ];


  const customStyles = {
    option: (provided, { data , isFocused, isSelected }) => ({
        ...provided,
        backgroundColor: isFocused ? '#f0f0f0' : isSelected ? 'white' : 'white', 
        color:
            data.value === 'Correct' ? 'green' :
            data.value === 'Wrong' ? 'red' :
            data.value === 'N.A' ? '#c78c06' : 'black',
       
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '145px',
        backgroundColor: 'white'
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color:
          data.value === 'Correct' ? 'green' :     
          data.value === 'Wrong' ? 'red' :         
          data.value === 'N.A' ? '#c78c06' : 'black',
  })
  };
  const customStyles1 = {
    option: (provided, { data , isFocused, isSelected }) => ({
        ...provided,
        backgroundColor: isFocused ? '#f0f0f0' : isSelected ? 'white' : 'white', 
        color:
            data.value === 'Profit' ? 'green' :
            data.value === 'Loss' ? 'red' :
            data.value === 'N.A' ? '#c78c06' : 'black',
        // color: 'white',
        // ':hover': {
        //     backgroundColor:
        //         data.value === 'Correct' ? 'darkgreen' :
        //         data.value === 'Wrong' ? 'darkred' :
        //         data.value === 'N.A' ? 'gold' : 'lightgray',
        //     color: 'white'
        // },
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '145px' , 
        backgroundColor: 'white'
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color:
          data.value === 'Profit' ? 'green' :
          data.value === 'Loss' ? 'red' :   
          data.value === 'N.A' ? '#c78c06' : 'black', 
  })
  };



  
  // const handleSelectChange = (tradeid, fieldName, selectedValue, fullTradeData) => {
  //   const value = selectedValue?.value || null;
  //   console.log("kushagra length: " + savedtradedatavalues.length);
  //   console.log("hlo select", tradeid, fieldName, selectedValue);
  
  //   setSavedtradedatavalues((prevState) => {
  //     // Find the existing item by tradeid
  //     const existingItemIndex = prevState.findIndex(selectedItem => 
  //       selectedItem[tradeid] !== undefined
  //     );
  
  //     console.log("existingItemIndex", existingItemIndex);
      
  //     if (existingItemIndex !== -1) {
  //       // Update existing entry
  //       console.log("hlo kushagra");
  //       return prevState.map((selectedItem, index) =>
  //         index === existingItemIndex
  //           ? {
  //               ...selectedItem,
  //               [tradeid]: {
  //                 ...selectedItem[tradeid],
  //                 [fieldName]: value,
  //                 date: fullTradeData.date,
  //                 time: fullTradeData.time,
  //                 country: fullTradeData.country,
  //                 event: fullTradeData.event,
  //                 currency: fullTradeData.currency,
  //               },
  //             }
  //           : selectedItem
  //       );
  //     } else {
  //       console.log("bie kushagra");
  //       // Add new entry if it doesn't exist
  //       return [
  //         ...prevState,
  //         {
  //           [tradeid]: {
  //             [fieldName]: value,
  //             date: fullTradeData.date,
  //             time: fullTradeData.time,
  //             country: fullTradeData.country,
  //             event: fullTradeData.event,
  //             currency: fullTradeData.currency,
  //           },
  //         },
  //       ];
  //     }
  //   });
  // };
  
  

//   const handleSelectChange = (tradeid, fieldName, selectedValue, fullTradeData) => {
//   const value = selectedValue?.value || null;
//   console.log("kushagra length"+savedtradedatavalues.length)
// console.log("hlo select" , tradeid , fieldName , selectedValue)
// setSavedtradedatavalues((prevState) => {
    
//     const existingItem = prevState.find(selectedItem => 
//       (console.log("selectedItem.tradeid", Object.keys(selectedItem)[0]),
//       console.log("selectedItem" , selectedItem),
      
//       Object.keys(selectedItem)[0] === tradeid)
//     );
    
// console.log("existingItem" , existingItem)
//     if (existingItem ) {
//       // Update existing entry 
//       console.log("hlo kushagra")
//       return prevState.map((selectedItem) =>

//         Object.keys(selectedItem)[0]  === tradeid
//           ? {
//               ...selectedItem,
//               [tradeid]: {
//                 ...prevState[tradeid],
//                 [fieldName]: selectedValue.value,
//                 date: fullTradeData.date,
//                 time: fullTradeData.time,
//                 country: fullTradeData.country,
//                 event: fullTradeData.event,
//                 currency: fullTradeData.currency,
//                  // For `Select` component
//               },
//             }
//           : selectedItem
//       );
//     }
//      else {
//       console.log("bie kushagra")
//       // Add new entry if it doesn't exist
//       return [
//         ...prevState,
//         {
//           // _id: tradeid,
//           [tradeid]: {
//             ...prevState[tradeid],
//             [fieldName]: selectedValue.value,
//             date: fullTradeData.date,
//             time: fullTradeData.time,
//             country: fullTradeData.country,
//             event: fullTradeData.event,
//             currency: fullTradeData.currency,
//              // For `Select` component
//           },
//         },
//       ];
//     }
//   });

// };
// const handleSelectChange = (tradeid, fieldName, selectedValue, fullTradeData) => {
//   const value = selectedValue?.value || null;
//   console.log("kushagra length: " + savedtradedatavalues.length);
//   console.log("hlo select", tradeid, fieldName, selectedValue);

//   setSavedtradedatavalues((prevState) => {
//     // Find the existing item by tradeid
//     const existingItemIndex = prevState.findIndex(selectedItem => 
//       selectedItem._id === tradeid  // Ensure you are checking the correct key
//     );

//     console.log("existingItemIndex", existingItemIndex);
    
//     if (existingItemIndex !== -1) {
//       // Update existing entry
//       console.log("hlo kushagra");
//       return prevState.map((selectedItem, index) =>
//         index === existingItemIndex
//           ? {
//               ...selectedItem,
//               [fieldName]: value,  // Ensure fieldName is set correctly
//               date: fullTradeData.date,
//               time: fullTradeData.time,
//               country: fullTradeData.country,
//               event: fullTradeData.event,
//               currency: fullTradeData.currency,
//             }
//           : selectedItem
//       );
//     } else {
//       console.log("bie kushagra");
//       // Add new entry if it doesn't exist
//       return [
//         ...prevState,
//         {
//           _id: tradeid,  // Set the tradeid as the key
//           [fieldName]: value,
//           date: fullTradeData.date,
//           time: fullTradeData.time,
//           country: fullTradeData.country,
//           event: fullTradeData.event,
//           currency: fullTradeData.currency,
//         },
//       ];
//     }
//   });
// };

// const [updatedTradeIds, setUpdatedTradeIds] = useState([]);
// <---ashi new chnges here---->

const handleSelectChange = (tradeid, fieldName, selectedValue, fullTradeData) => {
  // console.log("selectedValue" , selectedValue)
  const value = selectedValue?.value || selectedValue || null;
  // console.log("value" ,value)
  // console.log("fullTradeData: " + fullTradeData);
  // console.log("hlo select", tradeid, fieldName, selectedValue);

  setSavedtradedatavalues((prevState) => {
    // Find the existing item by tradeid
    const existingItemIndex = prevState.findIndex(selectedItem => 
      selectedItem._id === tradeid  // Ensure you are checking the correct key
    );

    // console.log("existingItemIndex", existingItemIndex);
    
    if (existingItemIndex !== -1) {
      // Update existing entry
      const updatedItem = {
        ...prevState[existingItemIndex],
        [fieldName]: value,  // Ensure fieldName is set correctly
        date: fullTradeData.date,
        time: fullTradeData.time,
        country: fullTradeData.country,
        event: fullTradeData.event,
        currency: fullTradeData.currency,
      };

      if (JSON.stringify(prevState[existingItemIndex]) !== JSON.stringify(updatedItem)) {
        // Send updated item directly to backend
        set_SendUpdatedItemToBackend((prevItems) => {
          // Check if the item is already in the array
          const itemExists = prevItems.find(item => item._id === updatedItem._id);
          // If it exists, replace it, otherwise add it
          return itemExists 
            ? prevItems.map(item => item._id === updatedItem._id ? updatedItem : item) 
            : [...prevItems, updatedItem];
        });
      }
      
      const updatedState = prevState.map((selectedItem, index) =>{
       
       return index === existingItemIndex ? updatedItem : selectedItem
      }
      );
     
      return updatedState;
      
      // return prevState.map((selectedItem, index) => {
      //   // Log each index, existingItemIndex, and result
      //   console.log("Current Index:", index);
      //   console.log("existingItemIndex:", existingItemIndex);
      //   console.log("Selected Item:", selectedItem);
      //   if (index === existingItemIndex) {
      //     console.log("Returning Updated Item:", updatedItem);
      //     console.log("Updated Item ----------------->:", updatedItem._id);
      //   } else {
      //     console.log("Returning Original Item:", selectedItem);
      //   }
      //   // setUpdatedTradeIds((prevIds) => [...new Set([...prevIds, tradeid])]);
      //   return index === existingItemIndex ? updatedItem : selectedItem;
      // });
    } else {
     
      const newItem = {
        _id: tradeid,
        [fieldName]: value,
        date: fullTradeData.date,
        time: fullTradeData.time,
        country: fullTradeData.country,
        event: fullTradeData.event,
        currency: fullTradeData.currency,
      };

    
      return [
        ...prevState,
        newItem,
      ];
    }
  });

  
};





  // const updateTradeData = async () => {
  //   try {
  //     setLoading1(true)
  //     const userid = localStorage.getItem("userregisterId");
  
     
  //     if (savedtradedatavalues.length === 0) {
  //       console.error("No saved trade data to update.");
  //       return;
  //     }
  //     let toastShown = false;
  //     let tradeUpdatedSuccessfully = false;

  //     const totalTrades = savedtradedatavalues.length;
  //     let completedRequests = 0;
  //     // const tradesToUpdate = savedtradedatavalues.filter(trade => updatedTradeIds.includes(trade._id));
  //     for (const trade of savedtradedatavalues) {
  //       // console.log("trade._id" , trade._id)
  //       const tradeid = trade._id;
     
  //       // Check if tradeid is valid (MongoDB ObjectID has 24 characters)
  //       if (tradeid.length !== 24) {
  //         console.error(`Invalid trade ID: ${tradeid}`);
  //         continue; // Skip invalid trade IDs
  //       }
  
      
  //       const apiUrl = `${process.env.REACT_APP_URL}/user/updatesavedtradingdata/${userid}/${tradeid}/${trade.date}`;
       
  //       const updateFields = {
  //         prediction: trade.prediction,
  //         trade_log : trade.trade_log ,
  //         trade_amount : trade.trade_amount,
  //         trade_time: trade.trade_time,
  //         trade_comment: trade.trade_comment,
  //         date: trade.date,
  //         time: trade.time,
  //         country: trade.country,
  //         event: trade.event,
  //         currency: trade.currency,
  //       };
  //       const response = await axios.put(apiUrl, updateFields);
  //       // console.log("API Response for tradeid", tradeid, response.data);
  //       setLoading1(true)
  //       if (response.data.message === 'Trade updated successfully' && response.status === 200) {
  //         if (!toastShown) {
  //           toast.success("Trades updated successfully!", { position: "top-right" }); 
            
  //           setTimeout(() => {
  //             toast.info("Wait, page will get reload again in few seconds...", { position: "top-right" });
             
  //             // window.location.reload();
  //           }, 4000);// Show success toast only once
  //           toastShown = true; // Set the flag to true after showing the toast
  //         }
  //         tradeUpdatedSuccessfully = true; // Mark that at least one trade was updated
  //       }
     
       
  //       completedRequests++;

  //       // After all requests are completed, show the loading alert
  //       if (completedRequests === totalTrades) {
  //         // toast.success("Trades updated successfully!", { position: "top-right" });

  //         // setTimeout(() => {
  //           // toast.info("Wait, page is getting loaded...", { position: "top-right" });
       
  //           window.location.reload();
  //         // }, 2000);
         
  //       }
      
       
       
  //     }
  //     if (!toastShown) {
  //       toast.info("No trades were updated.", { position: "top-right" }); // Optional: Inform the user if no trades were updated
  //       // setLoading1(false)
  //     }
     
  //     savedtradedata();
  //     setRefetchData(true);
  //     setLoading1(false)
  //   } catch (error) {
  //     setLoading1(false)
  //     // errorCount++; 
  //     console.error("Error updating trades:", error);
  //     if (error.response) {
  //       console.error("Error Response:", error.response.data); // Log detailed error
  //     }
  //     // toast.error("Failed to update trades.", { position: "top-right" });
  //   }
  // };   // final api new one
  

const updateTradeData = async () => {
    try {
        setLoading1(true);
        const userid = localStorage.getItem("userregisterId");

        if (savedtradedatavalues.length === 0) {
            console.error("No saved trade data to update.");
            return;
        }
    
        const updatePromises = sendUpdatedItemToBackend.map(async (trade) => {
            const tradeid = trade._id;
            // Check if tradeid is valid (MongoDB ObjectID has 24 characters)
            // if (tradeid.length !== 24) {
            //     console.error(`Invalid trade ID: ${tradeid}`);
            //     return null;
            // }

            const apiUrl = `${process.env.REACT_APP_URL}/user/updatesavedtradingdata/${userid}/${tradeid}/${trade.date}`;
            const updateFields = {
                prediction: trade.prediction,
                trade_log: trade.trade_log,
                trade_amount: trade.trade_amount,
                trade_time: trade.trade_time,
                trade_comment: trade.trade_comment,
                // date: trade.date,
                // time: trade.time,
                // country: trade.country,
                // event: trade.event,
                // currency: trade.currency,
            };

            try {
                const response = await axios.put(apiUrl, updateFields);
                if (response.data.message === 'Trade updated successfully' && response.status === 200) {
                    return true; 
                }
            } catch (error) {
                console.error("Error updating trade:", error);
            }
            return false; 
        });

        const results = await Promise.all(updatePromises);
        const tradesUpdated = results.filter(result => result).length;

        if (tradesUpdated > 0) {
            toast.success("Trades updated successfully!", { position: "top-right" });
            setTimeout(() => {
                toast.info("Reloading page .....", { position: "top-right" });
                window.location.reload();
            }, 1000);
        } else {
            toast.info("No trades were updated.", { position: "top-right" });
        }

        savedtradedata();
        setRefetchData(true);
    } catch (error) {
        console.error("Error updating trades:", error);
    } finally {
        setLoading1(false);
    }
};
  
const handleCountryChange = (values) => {

  setSelectedCountry(values);
};

const handleCurrencyChange = (values) => {

  setSelectedCurrency(values);
};



const handleTradelogChange = (values)=>{
setTradelogchange(values)
}

const handleDateChange = async(dates, dateStrings) => {
  setLoading(true);
  const userid = localStorage.getItem("userregisterId")
  // const tokenfromls = localStorage.getItem("token");
  // const h = { authorization: tokenfromls };
  const [startDate, endDate] = dateStrings;
  if (!dates) {
    setDateRange(null);
    // setSavedtradedatavalues([]); 
    savedtradedata();
    setLoading(false);
    return; 
  }
  try{
    const response = await axios.post(`${process.env.REACT_APP_URL}/user/datepicker_fromsavedtrade` ,  { startDate, endDate  , userid} , 
        // { headers: h }
      );
   const latestDocument = response.data
   let allTrades = [];

   latestDocument.forEach((doc) => {
     allTrades = allTrades.concat(doc.trades);
   });
   setSavedtradedatavalues(allTrades);
   setLoading(false);
   // console.log("allTrades", allTrades);
   
   
  
    
    // console.log("response.data" , response.data)
  }
    catch(error) {
     
      setLoading(false);
     
      console.error("Error fetching data:", error);
    };
    setDateRange(dates);
};


const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    // console.log("e.target.value" , e.target.value)
  };

  // Filter events based on the search term as you type
  const filteredEventsbysearch = sortedsavedData.filter(event => 
  {
    // console.log("event" , event)
   return event.event && event.event.toLowerCase().includes(searchTerm.toLowerCase())}
  );
  // console.log("filteredEventsbysearch" , filteredEventsbysearch)


  return (
    <>

    
     
      {
       Loading ?  
       <div className="flex items-center justify-center  mt-10 w-full">
       <RingLoader 
         height="80"
         width="80"
         color="#94A3B8"
         ariaLabel="bars-loading"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         className=" flex items-center justify-center  mt-10 w-[100%]"
       />
     </div>
      : <> 
      <UserNavbar />
      <div className="max-w-[100%] mx-[4%]  mt-[14vh] sm:mt-[40vh] md:mt-[20vh] lg:mt-[20vh] xl:mt-[15vh]">


        {sortedsavedData.length === 0 ? 
(
  <div
    className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl `}
  >
    Sorry!! you haven't saved any data yet .
  </div>
):(
       <>
         <h1 className="font-medium text-black m-2 text-[20px] mt-[2%]">History of selected trades :-</h1>

         <div className="flex-col sm:flex-col md:flex-col  lg:flex-row xl:flex-row flex">
         <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
            <Space style={{ width: "100%" }} direction="vertical">
              <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "200px"  , borderColor: "#9ca3af", borderWidth: "1px", borderStyle: "solid" , borderRadius:"8px" }}
                              placeholder={
                                <span style={{ color: "#666666" }}>
                                  Country
                                </span>
                              }
                              onChange={handleCountryChange}

                              // <-----stop movement above bottom---->
                              getPopupContainer={(trigger) => trigger.parentNode}
                              className=""
                            >
                              <Option value="UK">UK</Option>
                              <Option value="US">US</Option>
                              <Option value="CA">CA</Option>
                              <Option value="CH">CH</Option>
                              <Option value="EU">EU</Option>
                              <Option value="JP">JP</Option>
                              <Option value="AU">AU</Option>
                              <Option value="NZ">NZ</Option>
                              <Option value="DE">DE</Option>
                              <Option value="IT">IT</Option>
                              <Option value="FR">FR</Option>
              </Select>
            </Space>
         </div>
         <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
            <Space style={{ width: "100%" , borderColor: "#9ca3af" }} direction="vertical">
               <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "200px"  , borderColor: "#9ca3af", borderWidth: "1px", borderStyle: "solid" , borderRadius:"8px"}}
                              placeholder={
                                <span style={{ color: "#666666" }}>
                                  Currency
                                </span>
                              }
                              
                              onChange={handleCurrencyChange}

                              // <-----stop movement above bottom---->
                              getPopupContainer={(trigger) => trigger.parentNode}
                              className="border-black"
                            >
                              <Option value="GBP">GBP</Option>
                              <Option value="USD">USD</Option>
                              <Option value="CAD">CAD</Option>
                              <Option value="CHF">CHF</Option>
                              <Option value="EUR">EUR</Option>
                              <Option value="JPY">JPY</Option>
                              <Option value="AUD">AUD</Option>
                              <Option value="NZD">NZD</Option>
                            
               </Select>
            </Space>
         </div>
         <div className=" relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
              <Space direction="vertical" size={12}>
              {/* <RangePicker onChange={handleDateChange}/> */}
              <RangePicker 
              value={dateRange} 
              className="text-black placeholder-black-600"
              style={{ borderColor: "#9ca3af", color: "#9ca3af" , placeholderColor:"black" }}
              onChange={(dates, dateStrings) => handleDateChange(dates, dateStrings)} 
              />
              </Space>
         </div>
         <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
         <input
        type="text"
        placeholder="Search events"
           className=" w-[50%] sm:w-[50%] md:w-[30%] lg:w-full xl:w-full p-1 placeholder:text-center border border-gray-400 rounded-full focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
        value={searchTerm}
        onChange={handleSearch}
      />
     
         </div>
         <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
            <Space style={{ width: "100%" , borderColor: "#9ca3af" }} direction="vertical">
               <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "200px"  , borderColor: "#9ca3af", borderWidth: "1px", borderStyle: "solid" , borderRadius:"8px"}}
                              placeholder={
                                <span style={{ color: "#666666" }}>
                                  Trade
                                </span>
                              }
                              
                              onChange={handleTradelogChange}

                              // <-----stop movement above bottom---->
                              getPopupContainer={(trigger) => trigger.parentNode}
                              className="border-black"
                            >
                              <Option value="Yes">YES</Option>
                              <Option value="No">No</Option>
                             
                            
               </Select>
            </Space>
         </div>
         </div>







         <div className={`flex justify-end items-center mb-1 mt-[14%] sm:mt-[14%] md:mt-[8%] lg:mt-[6%] xl:mt-[2%] `} >
                <h1 className="cursor-pointer font-medium" title="Click here to save your trade" onClick={updateTradeData}>Save All Changes:</h1>
                <FiCheckCircle className="cursor-pointer" title="Save your data" />
    </div>
    
      <div className="overflow-x-auto">

      {/* <div className=" mx-auto mt-[8vh]"> */}
        {/* <div className=""> */}
      
        
              <>
            
             {/* <div className="flex justify-end items-center"> <button
    onClick={updateTradeData}
    className="bg-blue-500 text-white p-2 rounded-lg mb-4"
  >
    Save All Changes
  </button></div> */}
                

  
              <table className={` ${styles.tableuser} min-w-full border rounded overflow-x-scroll m-auto `}>

                <thead key={2} className="bg-gray-800   text-white ">
                  <tr key={1}>
                    <th className=" sticky top-0 py-2 px-4">Date</th>
                    <th className=" sticky top-0 py-2 px-4">Time(GMT)</th>
                    <th className="sticky top-0 py-2 px-4">Country</th>
                    <th className="sticky top-0 py-2 px-4">Currency</th>
                    <th className="sticky top-0 py-2 px-4">Event</th>
                    <th className="sticky top-0 py-2 px-4">Trade</th>
                    <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                    <th className="sticky top-0 py-2 px-4">Investing.com Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading view Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading Economics Var name</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Comments</th>
                    <th className="sticky top-0 py-2 px-4">Prediction</th>
                    <th className="sticky top-0 py-2 px-4">Trade log(profit/loss)</th>
                    <th className="sticky top-0 py-2 px-4">Trade Amount</th>
                    <th className="sticky top-0 py-2 px-4">Trade in time(seconds)</th>
                    <th className="sticky top-0 py-2 px-4">Trade comment</th>
                  </tr>
                </thead>

                <tbody>
                  
                  {sortedsavedData.filter(item =>
                
                  (selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true) &&
                  (selectedCurrency.length > 0 ? selectedCurrency.includes(item.currency) : true) &&
                  (filteredEventsbysearch.length > 0 ? 
                    filteredEventsbysearch.some(filteredItem => filteredItem.event === item.event) 
                    : true) 
                    && 
                    ( tradelogchange.length > 0 ? tradelogchange.includes(item.trade) : true) 
                )
              
                  
                  .map((item) => {
                    
                      // const selected = savedtradedatavalues.find(selectedItem => selectedItem.tradeid === item._id)?.trade || null; 
                    
                      // const matchingTrade = tradeUpdates[item._id] || null;

                  // Find the selected trade value from savedtradedatavalues
                  // const selected = savedtradedatavalues.find(selectedItem => selectedItem._id === item._id)?.trade_log || null;
              // console.log("selected" , selected)
              return(
              <>
                    <tr
                      key={item._id} className="cursor-pointer hover:bg-[#e2e3e4] text-center bg-gray-200" >
                      <td className="py-2 px-4">{item.date}</td>
                      <td className="py-2 px-4">{item.time}</td>
                      <td className="py-2 px-4">{item.country}</td>
                      <td className="py-2 px-4">{item.currency}</td>
                      <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer">{item.event}</td>
                      

                      <td className="py-2 px-4">{item.trade}</td>
                      <td className="py-2 px-4">{item.trade_grade}</td>
                      <td className="py-2 px-4">{item.investing_name}</td>
                      <td className="py-2 px-4">{item.trading_view}</td>
                      <td className="py-2 px-4">{item.trading_economics}</td>
                      <td className="py-2 px-4">{item.movement_currency}</td>
                      <td className="py-2 px-4">{item.movement_correlation}</td>
                      <td className="py-2 px-4">{item.flat_currency}</td>
                      <td className="py-2 px-4">{item.flat_correlation}</td>
                      <td className="py-2 px-4 min-w-[400px]">{item.comments}</td>
                      {/* <td className="py-2 px-4">{item.trade_log}</td>
                      <td className="py-2 px-4">{item.trade_time}</td>
                      <td className="py-2 px-4">{item.trade_comment}</td> */}
                      
                      <td className="py-2 px-4 min-w-[145px]"> 
                         
                         <Selects options={prediction} 
                            value={prediction.find(option => option.value === item.prediction)} 
                          onChange={(selected) =>  handleSelectChange(item._id, 'prediction', selected , item)} 
                          styles={customStyles}
                         />
                         </td>
                          <td className="py-2 px-4 min-w-[145px]"> 
                          
                           <Selects options={trade_log} 
                          //  value={trade_log.find(option => option.value === (tradeUpdates[item._id]?.trade_log || item.trade_log))} 
                            value={trade_log.find(option => option.value === item.trade_log)} 
                            onChange={(selected) =>  handleSelectChange(item._id, 'trade_log', selected , item)} 
                            styles={customStyles1}
                           />
                           </td>

                           {/* <td className="py-2 px-4 min-w-[145px]"> 
                         
                         <input type="text" name="trade_amount" id="trade_amount" className="focus:border-0 bg-gray-50 border border-gray-300
                          text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="" required 
                          // value={item.trade_amount}
                          value = {trade_log.find(option =>option.value === "Loss" ? "- item.trade_amount" : "item.trade_amount")}
                            onChange={(e) => handleSelectChange(item._id, 'trade_amount', e.target.value , item)}
                          
                           />
                           </td> */}

                          <td className="py-2 px-4 min-w-[145px]">
                              <input
                                  type="text"
                                  name="trade_amount"
                                  id="trade_amount"
                                  className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                  placeholder=""
                                  required
                                  value={
                                      trade_log.find(option => option.value === item.trade_log)?.value === "Loss"
                                          ? `-${Math.abs(Number(item.trade_amount)) || ''}`
                                          : item.trade_amount || ''
                                  }
                                  onChange={(e) => {
                                      const isLoss = trade_log.find(option => option.value === item.trade_log)?.value === "Loss";
                                      const value = e.target.value;

                                      // Remove non-numeric characters, except for "-"
                                      let numericValue = value.replace(/[^0-9.]/g, '');

                                      // If "Loss" is selected, add "-" to the start, but only if not already there
                                      if (isLoss) {
                                          numericValue = `-${numericValue}`;
                                      }

                                    
                                      handleSelectChange(item._id, 'trade_amount', numericValue, item);
                                  }}
                              />
                          </td>

                     
                           <td className="py-2 px-4 min-w-[145px]"> 
                         
                         <input type="text" name="trade_time" id="trade_time" className="focus:border-0 bg-gray-50 border border-gray-300
                          text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="" required 
                          value={item.trade_time}
                            onChange={(e) => handleSelectChange(item._id, 'trade_time', e.target.value , item)}
                          
                           />
                           </td>


                         
                           <td className="py-2 px-4 min-w-[270px]"> 
                         
                         <input type="text" name="trade_comment" id="trade_comment" className="focus:border-0 bg-gray-50 border border-gray-300
                          text-gray-900 text-sm rounded-lg  block w-full p-2.5 "  placeholder="" required 
                          value={item.trade_comment}
                          title={item.trade_comment}
                        onChange={(e) => handleSelectChange(item._id, 'trade_comment', e.target.value , item)}
                        />
                           
                           </td> 
                    </tr>
                    {/* <button
                    onClick={() => {console.log("hlo am clicked") ; updat(item._id)}}
                    className="bg-blue-500 text-white p-2 rounded-lg"
                  >
                    Save Changes
                  </button> */}

                    </>
              );
})}
                </tbody>
              </table>


              {
             Loading1 ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
             <LineWave 
               height="60"
               width="60"
               color="#111827"
               ariaLabel="bars-loading"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
               className=" w-10 h-10 flex items-center justify-center  mt-10 "
             />
           </div> :
            <div>
            
           </div>
          }
      
              </>
          
         
        {/* </div> */}
      {/* </div> */}

      </div>
      </> 
)}

          </div>
      </>
       }
          
  
     
    </>
  );
};

export default Savedtrade;
